<template>
  <div>
    <AppTitulo titulo="Manuais" />

    <div class="container">
      <div class="content custom-content">
        <ul v-if="company.buyer">
          <li class="item-manuals" v-for="manual in buyerManuals" :key="manual.id">
            <a :href="fetchManual(manual.fileName)" download>{{ manual.name }}</a>
          </li>
        </ul>

        <ul v-if="company.seller || !company.id">
          <li class="item-manuals" v-for="manual in filteredSellerManuals" :key="manual.id">
            <a :href="fetchManual(manual.fileName)" download>{{ manual.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppTitulo from '@/components/AppTitulo';
import { fileServer } from '@/utils/functions';

export default {
  name: 'Manuals',
  components: {
    AppTitulo,
  },
  data() {
    return {
      buyerManuals: [
        {
          id: 1,
          name: 'Convite e cadastro de usuários',
          fileName: 'Manual do Comprador - Convite e cadastro de usuários.pdf',
        },
        {
          id: 2,
          name: 'Publicação do edital e gerenciamento do Pregão Eletrônico',
          fileName:
            'Manual do Comprador - Publicação do edital e gerenciamento do Pregão Eletrônico.pdf',
        },
        {
          id: 3,
          name: 'Publicação do edital e gerenciamento da Dispensa Eletrônica',
          fileName:
            'Manual do Comprador - Publicação do edital e gerenciamento da Dispensa Eletrônica.pdf',
        },
      ],
      sellerManuals: [
        {
          id: 1,
          name: 'Cadastro de Fornecedor',
          fileName: '1-Manual do Fornecedor - Cadastro.pdf',
        },
        {
          id: 2,
          name: 'Cadastro e alteração de PIN',
          fileName: '2-Manual do Fornecedor - Cadastro e alteração de PIN.pdf',
        },
        {
          id: 3,
          name: 'Alteração de senha para acesso do usuário',
          fileName: '3-Manual do Fornecedor - Alteração de senha para acesso do usuário.pdf',
        },
        {
          id: 4,
          name: 'Alteração de dados cadastrais do usuário',
          fileName: '4-Manual do Fornecedor - Alteração de dados cadastrais do usuário.pdf',
        },
        {
          id: 5,
          name: 'Alteração de dados da empresa',
          fileName: '5-Manual do Fornecedor - Alteração de dados da empresa.pdf',
        },
        {
          id: 6,
          name: 'Convite e cadastro de usuários',
          fileName: '6-Manual do Fornecedor - Convite e cadastro de usuários.pdf',
        },
        {
          id: 7,
          name: 'Permissões de usuários',
          fileName: '7-Manual do Fornecedor - Permissões de usuários.pdf',
        },
        {
          id: 8,
          name: 'Cadastro de nova empresa',
          fileName: '8-Manual do Fornecedor - Cadastro de nova empresa.pdf',
        },
        {
          id: 9,
          name: 'Esqueceu a senha',
          fileName: '9-Manual do Fornecedor - Esqueceu a senha.pdf',
        },
        {
          id: 10,
          name: 'Inserção de logomarca da empresa',
          fileName: '10-Manual do Fornecedor - Inserção de logomarca da empresa.pdf',
        },
        {
          id: 11,
          name: 'Como participar do Pregão Eletrônico',
          fileName: 'Manual do Fornecedor - Como participar do Pregão Eletrônico.pdf',
        },
        {
          id: 12,
          name: 'Como participar da Dispensa Eletrônica',
          fileName: 'Manual do Fornecedor - Como participar da Dispensa Eletrônica.pdf',
        },
      ],
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      logged: 'getLogged',
    }),
    filteredSellerManuals() {
      return this.logged
        ? this.sellerManuals
        : this.sellerManuals.filter(element => element.id === 1);
    },
  },
  methods: {
    ...mapActions([]),
    fetchManual(fileName) {
      return fileServer(fileName, 'manuals');
    },
  },
};
</script>

<style scoped>
.custom-content {
  margin: 3rem 0;
}

.item-manuals {
  font-size: 1.1rem;
  margin: 1.5rem 0;
}
</style>
